button.BurgerClose {
    display: none;
}

button.BurgerOpen {
    display: none;
}

@media only screen and (max-width: 768px) {

    button.BurgerOpen {
        top: 2rem;
        right: 2rem;
        position: fixed;
        display: flex;
        width: 2rem;
        height: 2rem;
        justify-content: space-evenly;
        flex-flow: column nowrap;
        z-index: 10;
        background-color: transparent;
        border: 0px;
        cursor: pointer;
    }

    button.BurgerClose {
        top: 2rem;
        right: 2rem;
        position: fixed;
        display: flex;
        width: 2rem;
        height: 2rem;
        justify-content: space-evenly;
        flex-flow: column nowrap;
        z-index: 10;
        background-color: transparent;
        border: 0px;
        cursor: pointer;
    }

    button.BurgerClose:hover {
        cursor: pointer;
    }

    .BurgerLine {
        width: 2rem;
        height: 0.25rem;
        
        border: none;
        background-color: var(--font-color);
    }
    
    .BurgerLine:hover {
        border: none;
        border-color: var(--secondary-color);
    }

    button.BurgerClose {
        display: none;
    }
    
    button.BurgerOpen {
        display: none;
    }
}