:root {
    --primary-color: rgb(73, 73, 90);
    --secondary-color: rgb(255, 255, 255);
    --highlight-color: rgb(255, 255, 255);

    --font-color: rgb(151, 151, 151);
    --font-color-secondary: white;
    --font-color-subtle: rgb(78, 78, 78);
    
    --bg-color: rgb(40, 40, 49);
}