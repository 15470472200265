
ul.NavBar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    list-style: none;
    padding: 20px;
}

li a.NavItem {
    font-size: 1.5em;
    background-color: trasparent;
    color: var(--font-color);
    margin: 15px;
    width: 150px;
    height: 45px;
    text-align: center;
    color: var(--font-color);
    text-decoration: solid underline var(--font-color) 2px;
    text-underline-offset: 15px;
}

li a.NavItem:hover {
    color: var(--font-color-secondary);
    text-decoration: solid underline var(--font-color-secondary) 2px;
    text-underline-offset: 15px;
}

/* @media only screen and (max-width: 768px) {
    div.NavBarClosed {
        transition-property: transform, display;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
        transform: translate( 100%, 0);

        display: flex;
        flex-direction: column;
        
        position: fixed;
        right: 0;
        top: 0;
        background-color: var(--secondary-color);
        width: 20rem;
        height: 100vh;
        font-size: 1.75rem;
        font-weight: bold;
    }

    div.NavBarOpen {
        transition-property: transform, display;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
        transform: translate( 0, 0);

        display: flex;
        flex-direction: column;
        
        position: fixed;
        right: 0;
        top: 0;
        background-color: var(--secondary-color);
        width: 20rem;
        height: 100vh;
        font-size: 1.75rem;
        font-weight: bold;
    }

    ul.NavBar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60%;
        margin: 15%;
        padding: auto;
    }

    ul.NavBar li {
        margin: 42px;
        padding: auto;
    }
    
    ul a.NavItem:hover {
        color: white;
        text-decoration: solid underline white 2px;
        text-underline-offset: 15px;
    }
} */