.projectContainer {
    max-width: 800px;
    max-height: 450px;
    width: 320px;
    height: 160px;

    border: 1px solid var(--secondary-color);
    padding: 0px;
    margin: 0px;

    border-radius: 10px;
    overflow: hidden;
    background-color: transparent;
}

.projectContainer img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    size: scale-down;
}