div.Projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color);
}

div.Projects section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

div.Projects ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: auto;
    height: 100%;
}

.Projects button {
    border: none;
    background-color: transparent;
    margin: 10px 5px 10px 5px;
    padding: 0px;
    cursor: pointer;
}

.Projects button:hover {
    border: none;
    background-color: transparent;
    box-shadow: 2.5px 2.5px 25px var(--secondary-color);
}