html {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    background-color: var(--bg-color);
    color: var(--font-color);
    padding: 0;
    margin: 0;
}

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}