section.contact {
    margin: auto;
    margin-top: 5%;
    max-width: 1000px;
    border-radius: 15px;
    background-color: var(--primary-color);
}

section.contact_socials {
    border: none;
    margin: auto;
    margin-top: 5%;
    background-color: transparent;
}

.contact form.contact-form {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.contact form.contact-form h2 {
    margin: 10px;
    padding: 10px;
    font-size: 2em;
    font-weight: bold;
    color: var(--font-color);
}

.contact form.contact-form label {
    margin: 10px;
    padding: 10px;
    font-size: 1.5em;
    font-weight: bold;
    color: var(--font-color);
}

.contact form.contact-form input {
    width: 100%;
    margin: 10px;
    padding: 10px;
    border: 1px solid var(--font-color);
    border-radius: 5px;
}

.contact form.contact-form input:invalid {
    border: 1px solid red;
}

.contact form.contact-form textarea {
    width: 100%;
    min-height: 250px;
    margin: 10px;
    padding: 10px;
    border: 1px solid var(--font-color);
    border-radius: 5px;
    resize: none;
    display: block;
}

.contact form.contact-form textarea:invalid {
    border: 1px solid red;
}

.contact form.contact-form button {
    width: 100%;
    margin: 25px;
    padding: 10px;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    background-color: var(--primary-color);
    color: var(--font-color);
    font-size: 1.5em;
    font-weight: bold;
}

.contact_socials img {
    max-width: 90%;
    padding: 15px;

    border: none;
    background-color: transparent;
}

.contact_socials button {
    text-align: center;
    border: 1px solid white;
    border-radius: 10px;
    max-width: 250px;
    background-color: transparent;
    cursor: pointer;
}

.contact_socials button:hover {
    background-color: var(--secondary-color);
}