.Close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 50px;
    height: 50px;

    cursor: pointer;
    z-index: 100;

    font-size: 30px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    background-color: black;
}

.Close:hover {
    background-color: white;
    color: black;
}