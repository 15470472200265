section.intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
    margin: auto;
    color: var(--font-color-secondary);
}

#profilePicture {
    max-width: 350px;
    border-radius: 50%;
}

.intro-list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-message-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

ul.tech-list {
    display: flex;
    align-items: center;
    justify-content: center;
}

img.tech-logo {
    width: auto;
    height: 50px;
    margin: 15px;
}

@media only screen and (max-width: 768px) {

    section.intro {
        height: 85vh;
    }
    
    #profilePicture {
        width: 100%;
        max-width: 350px;
        border-radius: 50%;
    }

    .intro-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .intro-message-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 350px;
    }

    .intro-message-list li {
        margin: 0;
        padding: 0;
        line-height: 0px;
    }

    ul.tech-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    img.tech-logo {
        width: auto;
        max-height: 50px;
        margin: auto;
        padding: 10px;
    }
}