#ProjectPreviewBacker {
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 5px;
}

#ProjectPreview {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 1px;
    margin: 0px;
    transform: translate(-50%, -50%);
    background-color: var(--bg-color);
    border: 0px solid white;
    border-radius: 5px;

    width: 95%;
    max-width: 1080px;
    box-shadow: 0px 0px 25px var(--primary-color);
}

#ProjectPreview .previewLinks {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 100%;
}

#ProjectPreview .previewLinks li {
    display: flex;
    margin-left: 25px;
    margin-right: 25px;
}

#ProjectPreview .previewLinks button {
    display: flex;
    background-color: var(--primary-color);
    border-radius: 5px;
    border: 1px white (--secondary-color);
    padding: 15px;
    margin-bottom: 25px;
    overflow: hidden;
    width: 100%;
}

#ProjectPreview .previewLinks button:hover {
    display: flex;
    background-color: var(--secondary-color);
    border: 1px white (--secondary-color);
}

#ProjectPreview .previewLinks button a {
    text-decoration: none;
    font-size: 1.5rem;
    color: var(--font-color);
}

#ProjectPreview .slick-list {
    align-items: center;
    align-content: center;
    margin: 0;
    padding-top: 25px;
    overflow: hidden;
    max-width: 100%;
    max-height: 75vh;
}

#ProjectPreview .slick-list img {
    margin: auto;
    overflow: hidden;
    max-width: 95%;
    border-radius: 5px;
    object-fit: cover;
    border-radius: 5px;
    border: 2.5px solid white;
}

#ProjectPreview .slick-dots {
    position: relative;
    display: inline;
    bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#ProjectPreview .slick-dots button {
    display: block;
    inherits: none;
    color: black;
    margin-top: 0px;
    font-size: 0px;
    width: 30px;
    height: 30px;
    line-height: 0px;
    text-shadow: 0px 0 15px white;
}

#ProjectPreview .slick-dots button::before {
    inherits: none;
    font-size: 20px;
}